
const routes = [
  {
    path: '/',
    name: 'Actioner',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/IndexPage.vue') }
    ]
  },
  {
    path: '/action/:id',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '/action/:id', component: () => import('pages/ActionPage.vue') }
    ]
  },
  {
    path: '/free-reader',
    component: () => import('layouts/MainLayout.vue'),
    name: 'Čtečka',
    children: [
      { path: '/free-reader', component: () => import('pages/FreeReaderPage.vue') }
    ]
  },
  {
    path: '/action/:id/select/:group',
    component: () => import('layouts/MainLayout.vue'),
    name: 'Vyberte akci',
    children: [
      { path: '/action/:id/select/:group', component: () => import('pages/SelectPage.vue') }
    ]
  },
  {
    path: '/settings',
    component: () => import('layouts/MainLayout.vue'),
    name: 'Nastavení',
    children: [
      { path: '/settings', component: () => import('pages/SettingsPage.vue') }
    ]
  },
  {
    path: '/generator',
    component: () => import('layouts/MainLayout.vue'),
    name: 'Generátor',
    children: [
      { path: '/generator', component: () => import('pages/GeneratorPage.vue') }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes
